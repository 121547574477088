import { captchaService } from "./modules/captcha-service";
import { componentService } from "./modules/component-service";
import { cookieService } from "./modules/cookie-service";
import "./modules/core-js";
import { divvyService } from "./modules/divvy-service";
import { configureLoginButton } from "./modules/login-button";
import { marketoService } from "./modules/marketo-service";
import { segmentService } from "./modules/segment-service";
import { signupService } from "./modules/signup-service";
import { storageService } from "./modules/storage-service";
import { captureUtms } from "./modules/utm-service";
import { onReady } from "./modules/window-service";

captureUtms();
onReady(() => {
    marketoService.setupForms();
    signupService.setupForms();
    configureLoginButton();
    componentService.configureAll();
    divvyService.configureAll();
    segmentService.init();
    //captchaService.init();
    console.log('loaded');
});

window.addEventListener('unload', () => storageService.setPreviousPage(window.location.href));