class CookieService {
    getOneLogin = () => this.valueOf('ol');
    getTier = () => this.valueOf('signupTier');
    segmentGroupId = () => this.valueOf('ajs_group_id');
    segmentUserId = () => this.valueOf('ajs_user_id');

    getGaClientId() {
        const val = this.valueOf('_ga');
        return val && val.substring(6);
    }

    setSubmitDate(date?: Date) {
        const isoString = (date || new Date()).toISOString();
        document.cookie =  `mktSubmit=${isoString}; Domain=.bill.com; Path=/`;
    }

    private valueOf(name) {
        const cookies = ' ' + document.cookie + ';';
        const cookieName = ' ' + name + '=';
        const ix = cookies.indexOf(cookieName);
        if(ix === -1) return null;
        const valueIx = ix + cookieName.length;
        return cookies.slice(valueIx, cookies.indexOf(';', valueIx));
    }
}


export const cookieService = new CookieService();