class ProdEnvironment {
    signupDirect = 'https://gateway.prod.bill.com/signupservice/v1/signup/marketing';
    signupConsole = 'https://app-signup.us.bill.com/ConsoleSignup1';
    brInviteUrl = 'https://api.bill.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '258-FEM-750';
    segmentKey = 'b7SQxIgzchmkwydmWraoO2NRdwi0dRyq';
    directPricePlan = 'ppl01YLEUOPIFHFH2gb4';
}

class DevEnvironment extends ProdEnvironment {
    signupDirect = 'https://gateway-test.us.cashview.com/signupservice/v1/signup/marketing';
    signupConsole = 'https://app-signup-test.qa.cashview.com/ConsoleSignup1';
    brInviteUrl = 'https://app-test.cashview.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '117-IJE-550';
    segmentKey = 's5AojTRfV9Kts1U5SOpTrIgnOjFucioX';
    directPricePlan = 'ppl01FZCVEEKYLSONmgs';
}

class StageEnvironment extends ProdEnvironment {
    signupDirect = 'https://gateway.stage.bill.com/signupservice/v1/signup/marketing';
    signupConsole = 'https://app-signup-stage.us.bill.com/ConsoleSignup1';
    brInviteUrl = 'https://app-stage.cashview.com/api/v2/GetVendorInvitesExternal';
    munchkinId = '117-IJE-550';
    segmentKey = 's5AojTRfV9Kts1U5SOpTrIgnOjFucioX';
    directPricePlan = 'ppl01FHHBMCLTUKVG76o';
}

const isDev = window.location.host.includes('dev') || window.location.host.includes('test');
const isStg = isDev && window.location.href.includes('env=stage');
export const environment =  isStg ? new StageEnvironment() :
                            isDev ? new DevEnvironment() :
                                    new ProdEnvironment();