import { configureInputs, disableSubmitButton, populateFromStorage, getNumberOfEmployees } from "./form-service";
import { marketoService } from "./marketo-service";
import { storageService } from "./storage-service";
import { verifyUser } from "./user-service";

class DivvyService {
    configureAll() {
        this.setupSpendAndExpenseForms();
        this.addUtmsToLinks();
    }

    async submitSpendAndExpenseForm(form: HTMLFormElement): Promise<void> {
        const mkto = await marketoService.loadForm();
        await mkto.submit(form, {op_lt_product_interest: 'SE'});

        const formData = new FormData(form);
        const [softwareId] = marketoService.getAccountingSoftware(formData) || [];
        if (softwareId) {
            storageService.setBdcAccountingSoftwareForDivvy(softwareId);
        }

        const num = getNumberOfEmployees(form) || 1;
        let thankYouSmb = '/thankyou/demo-video';
        if(form.dataset.thankYouSmb) {
            const user = await verifyUser({ email: formData.get('email')?.toString()! });
            const [thankYouSmbNonBusiness, thankYouSmbBusiness] = form.dataset.thankYouSmb.split(';');
            thankYouSmb = user.isBusinessEmail === true 
                            ? thankYouSmbBusiness || '/thankyou/smb-next'
                            : thankYouSmbNonBusiness || thankYouSmb;
        }
        window.location.href = num < 21 ? thankYouSmb.trim() : '/thankyou';

    }

    private setupSpendAndExpenseForms() {
        document.querySelectorAll<HTMLFormElement>('form[data-sne-form]').forEach(form => {
            configureInputs(form);
            populateFromStorage(form);

            form.onsubmit = ev => {
                ev.preventDefault();
                ev.stopPropagation();
                
                disableSubmitButton(ev.submitter);
                
                this.submitSpendAndExpenseForm(form);
                return false;
            };
        });
        marketoService.loadForm(); //preload marketo form
    }

    private addUtmsToLinks() {
        let utms:string;
        document.querySelectorAll<HTMLAnchorElement>("a[href^='https://apply.divvy.co']").forEach(a => {
            utms = utms || this.encodeUtmValues();
            a.href = "https://apply.divvy.co/?" + utms;
        });
        //remove after RCA test completes
        document.querySelectorAll<HTMLAnchorElement>("a[href^='https://apply-dev-bdc-stg.divvy.co/']").forEach(a => {
            utms = utms || this.encodeUtmValues();
            a.href = "https://apply-dev-bdc-stg.divvy.co/?" + utms;
        });
    }

    private encodeUtmValues() {
        const utms = {
            lt: storageService.getLastTouchValues(),
            ft: storageService.getFirstTouchValues(),
            clickIds: storageService.getClickIds(),
            previousPage: storageService.getPreviousPage(),
            isFirstTouch: storageService.getIsFirstTouchSession()
        };
        const ud = storageService.getUserData();
        if (ud?.email) {
            // add email field explicitly for backward compatibility
            utms['email'] = ud.email;
            utms['applicantData'] = {
                email: ud.email,
                firstName: ud.firstName,
                lastName: ud.lastName,
                phone: ud.phone,
                companyName: ud.name,
                numberOfEmployees: ud.numberOfEmployees,
                accountingSoftware: storageService.getBdcAccountingSoftwareForDivvy()
            }
        }

        // filter out all empty values
        // create an array of single key objects
        const params = Object.keys(utms)
                        .filter(key => Boolean(utms[key]))
                        .map(key => ({[key]: utms[key]}));
        // const ec = window.btoa(JSON.stringify(params));
        // console.log('encode', ec);
        // console.log('decode:', window.atob(ec));
        return window.btoa(JSON.stringify(params));
    }

}

export const divvyService = new DivvyService();