import { AnalyticsBrowser } from '@segment/analytics-next';
import { environment } from './environment';
import { storageService } from './storage-service';
import { getABTestVariances } from './form-service';
import { cookieService } from './cookie-service';

const staticData = {
    _app_host_name: "marketing site",
    _app_host_version: "1.0.0",
    _project_id: "2746",
    _bdc_org_id: cookieService.segmentGroupId(),
    _bdc_user_id: cookieService.segmentUserId()
}

class SegmentService {
    private _analytics: any;
    private get analytics() {
        if (this._analytics) return this._analytics;
        this._analytics = AnalyticsBrowser.load({ writeKey: environment.segmentKey });
        return this._analytics;
    }

    init() {
        this.analytics.page(null, null, staticData);
        this.trackClick("nav a[href='/signup'], nav a[href='/demo-request']", 'nav__lead_cta__fired');
        this.trackClick('[data-segment-click]');
        document.querySelectorAll<HTMLElement>("a[href^='https://apply.divvy.co']").forEach(el => {
            el.addEventListener('click', () => {
                this.analytics.track('se__apply_now__triggered', {
                    ft: storageService.getFirstTouchValues(),
                    lt: storageService.getLastTouchValues(), 
                });
            });
        });
    }

    trackClick(selector: string, eventName?: string) {
        document.querySelectorAll<HTMLElement>(selector).forEach(el => {
            el.addEventListener('click', () => {
                this.analytics.track(eventName || el.dataset.segmentClick || 'marketing__click', {
                    ... staticData,
                    link_text: el.innerText,
                    button_identifier: el.id
                });
            });
        });
    }

    trackMktoSubmit(form: HTMLFormElement, mktoData: Object) {
        const event = form.dataset.segment || 'lead__is__submitted';
        const product_interest = mktoData['op_lt_product_interest'] ||
                                'sneForm' in form.dataset ? 'SE' : 
                                'mktoForm' in form.dataset ? 'APAR': null;

        const org_type = mktoData['Firm_Type__c'] || mktoData['Type__c'];
        const accounting_software_choice = mktoData['Accounting_Package__c'];
        const email = mktoData['Email'];
        const optIn = mktoData['optIn'];
        this.analytics.identify(null, {
            ...staticData,
            email,
            first_name: mktoData['FirstName'],
            last_name: mktoData['LastName']
        });
        this.analytics.track(event, {
            email,
            tier: cookieService.getTier(),
            ft: storageService.getFirstTouchValues(),
            lt: storageService.getLastTouchValues(),
            AB_test_value: getABTestVariances(),
            ...staticData,
            ...optIn && { optIn },
            ...org_type && { org_type },
            ...product_interest && { product_interest },
            ...accounting_software_choice && { accounting_software_choice },
            ...'emailCaptureForm' in form.dataset && { form_type: 'email-capture' }
        });
    }
}
export const segmentService = new SegmentService();